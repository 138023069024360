import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  editAssembleia,
  getOrdensAssembleia,
  loadFormAssembleia,
  getInvestidoresAssembleia,
  getCustodiantesAssembleia,
  getResumoVotosAssembleia,
  loadOperacoes,
} from "../../../../actions/assembleia/AssembleiaAction";
import ContainerPage from "../../../common/container/ContainerPage";
import AssembleiaForm from "./form/AssembleiaForm";
import { ASSEMBLEIA, READ } from "../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../actions/authorization/AuthorizationAction";

let AssembleiaUpdatePage = (props) => {
  const {
    authorizationFunction,
    loadFormAssembleia,
    editAssembleia,
    getOrdensAssembleia,
    getInvestidoresAssembleia,
    getResumoVotosAssembleia,
    getCustodiantesAssembleia,
    loadOperacoes,
  } = props;

  let user = JSON.parse(localStorage.getItem("userLogged"));
  let acessosUser = user.acessos;
  let isAdmin = acessosUser.some((acesso) => acesso === 1);
  const areas = user.areasAtuacoes.map((ar) => ar.id);
  const permitido = areas.some((area) => [4].includes(area))

  React.useEffect(() => {
    let idAssembleia = props.match.params.idAssembleia;
    async function fetchLoad() {
      await authorizationFunction(
        { idAcao: READ, idFuncionalidade: ASSEMBLEIA, validarAreaAtuacao: true },
        async () => {
          const resp = await loadFormAssembleia(idAssembleia)
          
          if (resp) {
            await getOrdensAssembleia(idAssembleia)

            const { status, patrimonioSeparado } = resp.assembleia

            if (status.key >= 2) {
              await getInvestidoresAssembleia(idAssembleia)
              await getCustodiantesAssembleia(idAssembleia)
            }

            if (status.key >= 3) {
              await getResumoVotosAssembleia(idAssembleia)
            }

            if (patrimonioSeparado && patrimonioSeparado?.key) {
              await loadOperacoes(patrimonioSeparado.key)
            }
          }
        }
      );
    }

    fetchLoad();
  }, []);

  return (
    <ContainerPage>
      <AssembleiaForm
        readOnly={(isAdmin || permitido) ? false : true}
        onSubmit={editAssembleia}
        title={"Editar Assembléia"}
      />
    </ContainerPage>
  );
};


const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editAssembleia,
      authorizationFunction,
      loadFormAssembleia,
      getOrdensAssembleia,
      getInvestidoresAssembleia,
      getResumoVotosAssembleia,
      getCustodiantesAssembleia,
      loadOperacoes,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AssembleiaUpdatePage);
