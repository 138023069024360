/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import ContasReceberForm from "../../contasReceber/pages/form/ContasReceberForm";
import {
  loadContasReceberForm,
  saveContasReceber,
} from "../../../../actions/contasReceber/ContasReceberAction";
import { CREATE, CONTAS_RECEBER } from "../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../actions/authorization/AuthorizationAction";

const ContasReceberIncludePage = (props) => {
  const { loadContasReceberForm, saveContasReceber, authorizationFunction } = props;

  React.useEffect(() => {
    async function fetchLoad() {
      await authorizationFunction(
        { idAcao: CREATE, idFuncionalidade: CONTAS_RECEBER, validarAreaAtuacao: true },
        () => loadContasReceberForm()
      );
    }
  
    fetchLoad();
  }, []);

  return (
    <ContasReceberForm
      title={"Novo - Contas a Receber"}
      onSubmit={saveContasReceber}
      readOnly={false}
      floatButtonVisible={true}
    />
  );
};

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      loadContasReceberForm,
      saveContasReceber,
      authorizationFunction,
    },
    dispatch
  );

export default withRouter(
  connect(null, mapDispacthToProps)(ContasReceberIncludePage)
);
