import React from "react";
import { formatDecimal } from "../../../utils/helpers/NumberHelper";

const Textarea = ({
  input,
  meta,
  label,
  inputId,
  className,
  outerClass,
  readOnly,
  placeholder,
  maxLength,
  minHeight,
  resize,
  rows,
  cols,
  required,
  trimOnBlur,
}) => (
  <div
    className={`form-group field-floating has-float-label ${outerClass} ${
      readOnly ? "disabled-field pb-2" : ""
    }`}
  >
    <textarea
      {...input}
      className={`form-control ${className}`}
      id={`txtfield_${inputId}`}
      readOnly={readOnly}
      placeholder={placeholder}
      maxLength={maxLength}
      style={{
        minHeight: minHeight ? `${minHeight}em` : "none",
        resize: resize || "none",
      }}
      rows={rows}
      cols={cols}
      onBlur={(e) => {
        if (!readOnly) return trimOnBlur && input.onBlur(e.target.value.trim().replace(/;/g, "").replace(/[\r\n]+/g, " ").replace(/\s+/g, " "))
      }}           
    />
    {label && (
      <label htmlFor={inputId} className="control-label" id={`lbl_${inputId}`}>
        {label}{" "}
        {required && (
          <span className="text-warning font-weight-bold ml-1">*</span>
        )}
      </label>
    )}
    {(maxLength || meta?.touched && meta?.error) && (
      <div className="d-flex flex-row align-items-center justify-content-between">
        {(meta?.touched && meta?.error) && (<p className="error-msg sp-error text-danger f-12 mb-0 w-100">{meta.error}</p>)}
        {maxLength && (<p className="text-right m-0 p-0 f-10 w-100">{`${formatDecimal(input.value.length, 0)}/${formatDecimal(maxLength, 0)}`}</p>)}
      </div>
    )}
  </div>
);

export default Textarea;
