/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Field,
  formValueSelector,
  arrayInsert,
  arrayRemove,
  change,
} from "redux-form";
import { bindActionCreators } from "redux";
import { multiply } from "mathjs";
import { createNumberMask, createTextMask } from 'redux-form-input-masks';

import { convertCurrencyToNumber } from "../../../../../../../utils/helpers/NumberHelper";

import If from "../../../../../../common/If/If";
import Input from "../../../../../../common/input/Input";
import MaskedInput from "../../../../../../common/input/MaskedInput";
import DropdownListInput from "../../../../../../common/input/DropdownListInput";
import MultSelectListInput from "../../../../../../common/input/MultSelectListInput";
import RatingPassivoList from "./RatingPassivoList";

import {
  inserirNomePastaCedoc,
  changeDataEmissao,
  changeMoeda,
  loadFormPassivoPatrimonio,
  loadDataFundo,
  limpaDependenteOnChange,
  loadClassesFundo,
} from "../../../../../../../../src/actions/operacao/PassivoAction";
import Button from "../../../../../../common/button/Button";
import { CNPJ, customFormatCnpjCpf, alphanumeric } from "../../../../../../../utils/helpers/StringHelper";
import MaskedFormatInput from "../../../../../../common/input/MaskedFormatInput";
import { msgError } from "../../../../../../../utils/helpers/OthersHelpers";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");

class PassivoFormGeral extends Component {
  componentWillMount() {
    if (!this.props.gestora) {
      if (this.props.contaArrecadadoraList.length === 0) {
        this.addContaArrecadadora(this.props.contaArrecadadoraList.length);
      }
    }
  }

  addContaArrecadadora(index) {
    if (!this.props.readOnly) {
      this.props.arrayInsert("passivoForm", "contaArrecadadoraList", index, this.props.gestora ? {} : {contaArrecadadoraOrigemRecurso: []});
    }
  }

  removeContaArrecadadora(index) {
    if (!this.readOnly && this.props.contaArrecadadoraList.length > 1) {
      this.props.arrayRemove("passivoForm", "contaArrecadadoraList", index);
    }
  }

  calcularTotalEmitido(value, fieldName) {
    value = parseFloat(value)

    let calcTotalEmitido = null;

    if (fieldName === "quantidadeEmitida" && this.props.precoUnitarioSelector) {
      calcTotalEmitido = multiply(value, convertCurrencyToNumber(this.props.precoUnitarioSelector));
    } else if (fieldName === "precoUnitario" && this.props.quantidadeEmitidaSelector) {
      calcTotalEmitido = multiply(value, parseFloat(this.props.quantidadeEmitidaSelector));
    }

    this.props.change("passivoForm", "totalEmitido", calcTotalEmitido);
  }

  render() {
    const {
      readOnly,
      codigoPatrimonio,
      possuiPerfilAnalista,
      existenciaPrePagamento,
      contaArrecadadoraList,
      tituloList,
      gestorFiduciarioList,
      coordenadorLiderList,
      assessorLegalList,
      escrituradorList,
      agenteFiduciarioList,
      agentePagamentoList,
      camaraLiquidacaoList,
      moedasList,
      bancoLiquidanteList,
      classeList,
      naturezaList,
      tipoOfertaList,
      concentracaoList,
      possibilidadePrePagamentoList,
      formaPrePagamentoList,
      tipoContaArrecadadoraList,
      bancoList,
      emissorList,
      statusOperacaoPassivoList,
      patrimonioSeparadoList,
      divulgacaoList,
      produtoList,
      agendaRatingsList,
      usuarioList,
      usuarioEstruturacaoList,
      passivoOperacao,
      changeMoeda,
      moedaDinamica,
      loadFormPassivoPatrimonio,
      tipoOferta,
      // 
      gestora,
      //
      loadDataFundo,
      loadClassesFundo,
      limpaDependenteOnChange,
      fundoList,
      classesFundoList,
      subclassesFundoList,
      administradorFiduciarios,
      gestoras,
      statusOperacaoPassivo,
      gestorasII,
      consultorCreditos,
      controladorias,
      distribuidores,
      custodiantes,
      agenteMonitoramentos,
      agenteFormalizacoes,
      agenteCobrancaJudiciais,
      agenteCobrancaExtrajudiciais,
      auditorias,
      agenciaRatings,
      possuiMultiGestao,
      classesLoaded,
      subclassesLoaded,
      selosList,
      relacionamentoList,
      tipoInvestidorList,
      origemRecursosList,
      tipoRendimentoList,
    } = this.props;

    let EXE_ATI = statusOperacaoPassivo?.key == 1 || statusOperacaoPassivo?.key == 4;
    let RESG = statusOperacaoPassivo?.key == 3;
    let EXE = statusOperacaoPassivo?.key == 4;

    let isRCVM160 = tipoOferta?.value.includes("RCVM 160");

    const currencyFormat = {
      prefix: '',
      suffix: '',
      decimalPlaces: 0,
      locale: 'pt-BR',
      allowEmpty: true,
    }

    const currencyMaskCota = createNumberMask(currencyFormat)

    const currencyMaskPassivo = createNumberMask({
      ...currencyFormat,
      onChange: value => this.calcularTotalEmitido(value, "quantidadeEmitida"),
    })

    const textFormat = {
      pattern: "99999999-9999-9999-9999-999999999999",
      maskDefinitions: { 9: { regExp: /[0-9A-Za-z]/ } },
      guide: false,
      stripMask: false,
      allowEmpty: true,
    }

    const textMask = createTextMask(textFormat)

    let moedaSimbolo = moedaDinamica || passivoOperacao?.moeda?.simbolo || "R$";

    let user = JSON.parse(localStorage.getItem("userLogged"));
    let isAllowed = user.acessos.some((acesso) => acesso === 1 || acesso === 3);

    let passivoControle = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Controle</span>
          <div className="row">
            <div className="col-12 col-lg-6">
              <Field
                component={DropdownListInput}
                inputId="patrimonioSeparado"
                name="patrimonioSeparado"
                label="Patrimônio Separado"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                options={patrimonioSeparadoList}
                textField="descricao"
                valueField="id"
                filter="contains"
                onChange={(val) => loadFormPassivoPatrimonio(val?.id)}
                required
              />
            </div>

            <div className="col-12 col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="produtoPassivo"
                name="produtoPassivo"
                label="Produto"
                options={produtoList}
                textField="value"
                valueField="key"
                filter="contains"
                readOnly={true}
                outerClassName={"disabled-field"}
                required
              />
            </div>

            <div className="col-12 col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="divulgacao"
                name="divulgacao"
                label="Divulgação"
                options={divulgacaoList}
                textField="value"
                valueField="key"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="usuarioEstruturacao"
                name="usuarioEstruturacao"
                label="Estruturação"
                options={usuarioEstruturacaoList}
                textField="nome"
                valueField="id"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="usuario"
                name="usuario"
                label="Gestão"
                options={usuarioList}
                textField="nome"
                valueField="id"
                filter="contains"
                readOnly={true}
                outerClassName={"disabled-field"}
              />
            </div>

            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="usuarioServicoCredito"
                name="usuarioServicoCredito"
                label="Serviço de Crédito"
                options={usuarioList}
                textField="nome"
                valueField="id"
                filter="contains"
                readOnly={true}
                outerClassName={"disabled-field"}
              />
            </div>

            <div className="col-12 col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="statusOperacaoPassivo"
                name="statusOperacaoPassivo"
                label="Status Operação"
                options={statusOperacaoPassivoList}
                textField="value"
                valueField="key"
                readOnly={readOnly}
                outerClassName={readOnly ? "disabled-field" : ""}
                filter="contains"
                required
                onChange={(e, value) => {
                  if (value?.key == 1 && !isAllowed) {
                    msgError("Usuário não possui permissão para ativar a operação");
                    e.preventDefault();
                    return;
                  }

                  limpaDependenteOnChange("dataResgate", null)
                  limpaDependenteOnChange("dataExecucao", null)
                }}
              />
            </div>

            <div className="col-lg-3 col-lg-6">
              <Field
                component={Input}
                inputId="idCedoc"
                name="idCedoc"
                label="ID da Pasta no CEDOC"
                type="text"
                maxLength={36}
                outerClassName={readOnly && "disabled-field"}
                icon="info-circle"
                iconColor="#630a3679"
                tooltipMessage="ID da Pasta do Patrimônio Separado no CEDOC"
                tooltipId="id-cedoc"
                {...textMask}
                required={EXE_ATI}
                readOnly={readOnly}
              />
            </div>

            <div className="col-12 col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="statusPagamento"
                name="statusPagamento"
                label="Status Pagamento"
                options={[
                  { key: 1, value: "Ativo", raw: "Ativo" },
                  { key: 2, value: "Inativo", raw: "Inativo" },
                ]}
                textField="value"
                valueField="key"
                readOnly={(readOnly || EXE_ATI || (!EXE_ATI && !isAllowed))}
                outerClassName={(readOnly || EXE_ATI || (!EXE_ATI && !isAllowed)) ? "disabled-field" : ""}
                filter="contains"
                required
              />
            </div>
          </div>
        </div>
      </div>
    );

    let caracteristicasOfertaBloco = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">
            Características da Oferta
          </span>
          <div className="row">
            <div className="col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="emissor"
                name="emissor"
                label="Emissor"
                options={emissorList}
                textField="descricaoComCnpj"
                valueField="id"
                readOnly={true}
                filter="contains"
                outerClassName={"disabled-field"}
                required
              />
            </div>

            <div className="col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="titulo"
                name="titulo"
                label="Título"
                options={tituloList}
                textField="descricao"
                valueField="id"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="tipoOferta"
                name="tipoOferta"
                label="Oferta"
                options={tipoOfertaList}
                textField="value"
                valueField="key"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-4 col-xl-2">
              <Field
                component={DropdownListInput}
                inputId="regimeFiduciario"
                name="regimeFiduciario"
                label="Regime Fiduciário"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-4 col-xl-2">
              <Field
                component={MaskedInput}
                inputId="emissao"
                name="emissao"
                label="Emissão"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                allowNegative={false}
                required
              />
            </div>

            <div className="col-lg-4 col-xl-2">
              <Field
                component={MaskedInput}
                inputId="serie"
                name="serie"
                label="Série"
                maxLength={3}
                allowNegative={false}
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-4 col-xl-2">
              <Field
                component={DropdownListInput}
                inputId="classeOperacao"
                name="classeOperacao"
                label="Classe"
                options={classeList}
                textField="descricao"
                valueField="id"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-4 col-xl-2">
              <Field
                component={DropdownListInput}
                inputId="naturezaOperacao"
                name="naturezaOperacao"
                label="Natureza"
                options={naturezaList}
                textField="descricao"
                valueField="id"
                filter="contains"
                readOnly={true}
                outerClassName={"disabled-field"}
              />
            </div>

            <div className="col-lg-4 col-xl-2">
              <Field
                component={DropdownListInput}
                inputId="concentracao"
                name="concentracao"
                label="Concentração"
                options={concentracaoList}
                textField="value"
                valueField="key"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-4">
              <Field
                component={Input}
                inputId="segmento"
                name="segmento"
                type="text"
                label="Segmento"
                readOnly={true}
                outerClassName={"disabled-field"}
              />
            </div>

            <div className="col-lg-3 col-xl-4">
              <Field
                component={DropdownListInput}
                inputId="camaraLiquidacao"
                name="camaraLiquidacao"
                label="Câmara de Liquidação"
                options={camaraLiquidacaoList}
                textField="razaoSocial"
                valueField="idEmpresaPrestadora"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-12 col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="moeda"
                name="moeda"
                label="Moeda"
                options={moedasList}
                textField="descricao"
                valueField="id"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                onChange={(v) => changeMoeda(v?.simbolo)}
                required
              />
            </div>

            <div className="col-12 col-lg-4">
              <Field
                component={MultSelectListInput}
                inputId="seloPassivoOperacoes"
                name="seloPassivoOperacoes"
                label="Selos"
                options={selosList}
                dataKey='id'
                textField="sigla"
                valueField="id"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                className={readOnly && 'clear-inside'}
              />
            </div>

            <div className="col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="revolvencia"
                name="revolvencia"
                label="Revolvência"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-4">
              <Field
                component={DropdownListInput}
                inputId="tipoInvestidor"
                name="tipoInvestidor"
                label="Tipo dos Investidores"
                options={tipoInvestidorList}
                textField="value"
                valueField="key"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
          </div>
        </div>
      </div>
    );

    let cotaBloco = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">
            Cota
          </span>
          <div className="row">
            <div className="col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="operacao.subClasse.idFundo"
                name="operacao.subClasse.idFundo"
                label="Fundo"
                options={fundoList}
                textField="nomeDoFundo"
                valueField="id"
                filter="contains"
                readOnly={readOnly}
                onChange={(_props, v) => loadDataFundo(v?.id)}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            {classesLoaded && (
              <div className="col-lg-3">
                <Field
                  component={DropdownListInput}
                  inputId="operacao.subClasse.idClasse"
                  name="operacao.subClasse.idClasse"
                  label="Classe"
                  options={classesFundoList}
                  textField="nomeDaClasse"
                  valueField="id"
                  filter="contains"
                  readOnly={readOnly}
                  onChange={(_props, v) => loadClassesFundo(v?.idFundo, v?.id)}
                  outerClassName={readOnly && "disabled-field"}
                  required
                />
              </div>
            )}

            {subclassesLoaded && (
              <div className="col-lg-3">
                <Field
                  component={DropdownListInput}
                  inputId="operacao.idSubClasse"
                  name="operacao.idSubClasse"
                  label="Subclasse"
                  options={subclassesFundoList}
                  textField="nomeSubClasse"
                  valueField="id"
                  filter="contains"
                  readOnly={readOnly}
                  outerClassName={readOnly && "disabled-field"}
                  required
                />
              </div>
            )}

            <div className="col-lg-3">
              <Field
                component={MaskedInput}
                inputId="emissao"
                name="emissao"
                label="Emissão"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                allowNegative={false}
                required
              />
            </div>

            <div className="col-lg-3">
              <Field
                component={MaskedInput}
                inputId="serie"
                name="serie"
                label="Série"
                allowNegative={false}
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
              />
            </div>

            <div className="col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="camaraLiquidacao"
                name="camaraLiquidacao"
                label="Câmara de Liquidação"
                options={camaraLiquidacaoList}
                textField="razaoSocial"
                valueField="idEmpresaPrestadora"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-3">
              <Field
                component={DropdownListInput}
                inputId="tipoOferta"
                name="tipoOferta"
                label="Oferta"
                options={tipoOfertaList}
                textField="value"
                valueField="key"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-3">
              <Field
                component={MaskedInput}
                inputId="quantidadeEmitida"
                name="quantidadeEmitida"
                label="Quantidade Emitida"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                allowNegative={true}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={8}
                fixedDecimalScale
                required
              />
            </div>

            <div className="col-lg-3">
              <Field
                component={MaskedInput}
                inputId="precoUnitario"
                name="precoUnitario"
                label="Valor da Cota de Emissão"
                readOnly={readOnly}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={8}
                fixedDecimalScale
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-3">
              <Field
                component={Input}
                inputId="dataEmissaoSerie"
                inputName="dataEmissaoSerie"
                type="date"
                name="dataEmissaoSerie"
                label="Data de Emissão"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                onChange={(e) => this.props.changeDataEmissao(e.target.value)}
                required
              />
            </div>
          </div>
        </div>
      </div>
    );

    let blocoModular1 = gestora ? cotaBloco : caracteristicasOfertaBloco;

    let passivoDatasPrecos = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Datas e Preços</span>
          <div className="row">
            <div className="col-lg-3">
              <Field
                component={Input}
                inputId="dataEmissaoSerie"
                inputName="dataEmissaoSerie"
                type="date"
                name="dataEmissaoSerie"
                label="Data de Emissão"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                onChange={(e) => this.props.changeDataEmissao(e.target.value)}
                required
              />
            </div>
            <div className="col-lg-3">
              <Field
                component={Input}
                inputId="dataVencimentoSerie"
                name="dataVencimentoSerie"
                type="date"
                label="Data de Vencimento"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
            <div className="col-lg-3 col-xl-2">
              <Field
                component={Input}
                inputId="quantidadeEmitida"
                name="quantidadeEmitida"
                label="Quantidade Emitida"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                allowNegative={false}
                type="tel"
                {...currencyMaskPassivo}
                required
              />
            </div>
            <div className="col-lg-3 col-xl-2">
              <Field
                component={MaskedInput}
                inputId="precoUnitario"
                name="precoUnitario"
                label="Preço Unitário"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={8}
                fixedDecimalScale
                placeholder={"R$ 0,00000000"}
                onValueChange={(e) =>
                  this.calcularTotalEmitido(e.value, "precoUnitario")
                }
                required
              />
            </div>
            <div className="col-lg-3 col-xl-2">
              <Field
                component={MaskedInput}
                inputId="totalEmitido"
                name="totalEmitido"
                label="Total Emitido"
                readOnly={true}
                prefix={`${moedaSimbolo} `}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={8}
                fixedDecimalScale
                icon="info-circle"
                iconColor="#630a3679"
                tooltipMessage="Quantitade Emitida X Preço Unitário"
                tooltipId="qe-pu"
                required
              />
            </div>
          </div>
          <div className="row">
            {RESG && (
              <div className="col-lg-3">
                <Field
                  component={Input}
                  inputId="dataResgate"
                  inputName="dataResgate"
                  type="date"
                  name="dataResgate"
                  label="Data de Resgate"
                  readOnly={readOnly}
                  outerClassName={readOnly && "disabled-field"}
                  required
                />
              </div>
            )}
            {EXE && (
              <div className="col-lg-3">
                <Field
                  component={Input}
                  inputId="dataExecucao"
                  inputName="dataExecucao"
                  type="date"
                  name="dataExecucao"
                  label="Data de Execução"
                  readOnly={readOnly}
                  outerClassName={readOnly && "disabled-field"}
                  required
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );

    let passivoCodigos = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Códigos</span>
          <div className="row">
            {!gestora && (
              <>
                <div className="col-lg">
                  <Field
                    component={Input}
                    inputId="apelidoOperacao"
                    name="apelidoOperacao"
                    type="text"
                    label="Apelido da Operação"
                    readOnly={readOnly}
                    outerClassName={readOnly && "disabled-field"}
                    required
                  />
                </div>

                <div className="col-lg">
                  <Field
                    component={Input}
                    inputId="codigoBolsa"
                    name="codigoBolsa"
                    type="text"
                    label="Código Bolsa"
                    readOnly={readOnly}
                    outerClassName={readOnly && "disabled-field"}
                  />
                </div>

                <div className="col-lg">
                  <Field
                    component={Input}
                    inputId="codigoCVM"
                    name="codigoCVM"
                    type="text"
                    label="Número do Registro CVM"
                    readOnly={readOnly}
                    required={isRCVM160 && EXE_ATI}
                    outerClassName={readOnly && "disabled-field"}
                  />
                </div>

                <div className="col-lg">
                  <Field
                    component={Input}
                    inputId="processoCVM"
                    name="processoCVM"
                    type="text"
                    classLabel="text-nowrap"
                    label="Número do Processo CVM"
                    readOnly={readOnly}
                    required={isRCVM160 && EXE_ATI}
                    outerClassName={readOnly && "disabled-field"}
                  />
                </div>
              </>
            )}

            <div className="col-lg">
              <Field
                component={Input}
                inputId="codigoCetipBbb"
                name="codigoCetipBbb"
                type="text"
                label="Código B3/Cetip"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg">
              <Field
                component={Input}
                inputId="codigoIsin"
                name="codigoIsin"
                type="text"
                label="Código ISIN"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
          </div>
        </div>
      </div>
    );

    let passivoParticipantes = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Participantes</span>
          <div className="row">
            <div className="col-lg-6 col-xl-3">
              <Field
                component={DropdownListInput}
                inputId="agentePagamento"
                name="agentePagamento"
                label="Agente de Pagamento"
                options={agentePagamentoList}
                textField="value"
                valueField="key"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-6 col-xl-3">
              <Field
                component={DropdownListInput}
                inputId="agenteFiduciario"
                name="agenteFiduciario"
                label="Agente Fiduciário"
                options={agenteFiduciarioList}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-6 col-xl-3">
              <Field
                component={DropdownListInput}
                inputId="gestorFiduciario"
                name="gestorFiduciario"
                label="Gestor Fiduciário"
                options={gestorFiduciarioList}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-6 col-xl-3">
              <Field
                component={DropdownListInput}
                inputId="coordenadorLider"
                name="coordenadorLider"
                label="Coordenador Líder"
                options={coordenadorLiderList}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-6 col-xl-3">
              <Field
                component={DropdownListInput}
                inputId="assessorLegal"
                name="assessorLegal"
                label="Assessor Legal"
                options={assessorLegalList}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-6 col-xl-3">
              <Field
                component={DropdownListInput}
                inputId="escriturador"
                name="escriturador"
                label="Escriturador"
                options={escrituradorList}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>

            <div className="col-lg-6 col-xl-3">
              <Field
                component={DropdownListInput}
                inputId="bancoLiquidante"
                name="bancoLiquidante"
                label="Banco Liquidante"
                options={bancoLiquidanteList}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                filter="contains"
                readOnly={readOnly}
                outerClassName={readOnly && "disabled-field"}
                required
              />
            </div>
          </div>
        </div>
      </div>
    );

    let cotasParticipantes = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Participantes</span>
          <div className="row">
            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="administradorFiduciario"
                name="administradorFiduciario"
                label="Administrador Fiduciário"
                options={administradorFiduciarios}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={true}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="gestora"
                name="gestora"
                label="Gestora"
                options={gestoras}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={true}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="possuiMultiGestao"
                name="possuiMultiGestao"
                label="Multigestão?"
                readOnly={true}
                filter="contains"
                onChange={() => limpaDependenteOnChange("gestoraII", null)}
                required
              />
            </div>

            {possuiMultiGestao == "Sim" && (
              <div className="col-lg-3 col-12">
                <Field
                  component={DropdownListInput}
                  inputId="gestoraII"
                  name="gestoraII"
                  label="Gestora II"
                  options={gestorasII}
                  textField="razaoSocialComCnpj"
                  valueField="idEmpresaPrestadora"
                  readOnly={true}
                  filter="contains"
                  required
                />
              </div>
            )}

            {/*  */}

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="consultorCredito"
                name="consultorCredito"
                label="Consultor de Crédito"
                options={consultorCreditos}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={true}
                filter="contains"
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="controladoria"
                name="controladoria"
                label="Controladoria"
                options={controladorias}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={true}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="distribuidor"
                name="distribuidor"
                label="Distribuidor"
                options={distribuidores}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="escriturador"
                name="escriturador"
                label="Escriturador"
                options={escrituradorList}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            {/*  */}

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="custodiante"
                name="custodiante"
                label="Custodiante"
                options={custodiantes}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="agenteMonitoramento"
                name="agenteMonitoramento"
                label="Agente de Monitoramento"
                options={agenteMonitoramentos}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="agenteFormalizacao"
                name="agenteFormalizacao"
                label="Agente de Formalização"
                options={agenteFormalizacoes}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="assessorLegal"
                name="assessorLegal"
                label="Assessor Legal"
                options={assessorLegalList}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
              />
            </div>

            {/*  */}

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="agenteCobrancaJudicial"
                name="agenteCobrancaJudicial"
                label="Agente de Cobrança Judicial"
                options={agenteCobrancaJudiciais}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="agenteCobrancaExtrajudicial"
                name="agenteCobrancaExtrajudicial"
                label="Agente de Cobrança Extrajudicial"
                options={agenteCobrancaExtrajudiciais}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="auditoria"
                name="auditoria"
                label="Auditor Independente"
                options={auditorias}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
                required
              />
            </div>

            <div className="col-lg-3 col-12">
              <Field
                component={DropdownListInput}
                inputId="agenciaRatingParticipante"
                name="agenciaRatingParticipante"
                label="Agência de Rating"
                options={agenciaRatings}
                textField="razaoSocialComCnpj"
                valueField="idEmpresaPrestadora"
                readOnly={readOnly}
                filter="contains"
              />
            </div>
          </div>
        </div>
      </div>
    );

    let blocoModular2 = gestora ? cotasParticipantes : passivoParticipantes;

    let ratingOperacao = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Rating</span>
          <RatingPassivoList dataList={agendaRatingsList} />
        </div>
      </div>
    );

    let passivoContasFiduciarias = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Contas Fiduciárias</span>
          <div className="d-flex flex-column w-100">
            <div className="col more-fields p-0">
              <TransitionGroup>
                {contaArrecadadoraList.map((item, index) => {
                  const vinculada = contaArrecadadoraList[index]?.tipoContaArrecadadora?.id == 5;

                  return (
                    <CSSTransition key={index} timeout={300} classNames="item">
                      <div key={index} className="mb-5">
                        <div className="row d-flex">
                          <div className="row col-12 col-lg pr-0 pr-lg-3">
                            <div className="pr-0 pr-lg-3 col-12 col-lg-4">
                                <Field
                                  component={DropdownListInput}
                                  inputId="banco"
                                  name={`contaArrecadadoraList[${index}].banco`}
                                  label="Banco"
                                  options={bancoList}
                                  textField="nome"
                                  valueField="id"
                                  filter="contains"
                                  readOnly={readOnly}
                                  outerClassName={readOnly && "disabled-field"}
                                  required
                                />
                            </div>
                            <div className="pr-0 pr-lg-3 col-12 col-lg-2">
                                <Field
                                  component={Input}
                                  filter={alphanumeric}
                                  inputId="agencia"
                                  name={`contaArrecadadoraList[${index}].agencia`}
                                  label="Agência"
                                  readOnly={readOnly}
                                  outerClassName={readOnly && "disabled-field"}
                                  required
                                />
                            </div>
                            <div className="pr-0 pr-lg-3 col-12 col-lg-2">
                                <Field
                                  component={Input}
                                  filter={alphanumeric}
                                  inputId="agenciaDigito"
                                  name={`contaArrecadadoraList[${index}].agenciaDigito`}
                                  label="Agência Dígito"
                                  readOnly={readOnly}
                                  outerClassName={readOnly && "disabled-field"}
                                />
                            </div>
                            <div className="pr-0 pr-lg-3 col-12 col-lg-2">
                                <Field
                                  component={Input}
                                  filter={alphanumeric}
                                  inputId="contaCorrente"
                                  name={`contaArrecadadoraList[${index}].contaCorrente`}
                                  label="Conta"
                                  readOnly={readOnly}
                                  outerClassName={readOnly && "disabled-field"}
                                  required
                                />
                            </div>
                            <div className="pr-0 pr-lg-3 col-12 col-lg-2">
                                <Field
                                  component={Input}
                                  filter={alphanumeric}
                                  inputId="digito"
                                  name={`contaArrecadadoraList[${index}].digito`}
                                  label="Conta Dígito"
                                  maxLength="3"
                                  readOnly={readOnly}
                                  outerClassName={readOnly && "disabled-field"}
                                  required
                                />
                            </div>

                            {/* ----- */}

                            <div className={`pr-0 pr-lg-3 col-12 col-lg-${vinculada ? '2' : '3'}`}>
                              <Field
                                component={DropdownListInput}
                                inputId="tipoContaArrecadadora"
                                name={`contaArrecadadoraList[${index}].tipoContaArrecadadora`}
                                label="Tipo"
                                options={tipoContaArrecadadoraList}
                                textField="descricao"
                                valueField="id"
                                filter="contains"
                                readOnly={readOnly}
                                outerClassName={readOnly && "disabled-field"}
                                required
                              />
                            </div>
                            {!gestora && vinculada && (
                              <>
                                <div className="pr-0 pr-lg-3 col-12 col-lg-2">
                                  <Field
                                    component={MaskedFormatInput}
                                    inputId="cnpj"
                                    maxLength="18"
                                    formatMask={customFormatCnpjCpf}
                                    className={"filter-input"}
                                    placeholder={CNPJ.placeholder}
                                    name={`contaArrecadadoraList[${index}].cnpj`}
                                    type="text"
                                    label="CNPJ"
                                    readOnly={readOnly}
                                    outerClassName={readOnly && "disabled-field"}
                                    required
                                  />
                                </div>
                                <div className="pr-0 pr-lg-3 col-12 col-lg-2">
                                  <Field
                                    component={Input}
                                    inputId="razaoSocial"
                                    name={`contaArrecadadoraList[${index}].razaoSocial`}
                                    type="text"
                                    label="Razão Social"
                                    readOnly={readOnly}
                                    outerClassName={readOnly && "disabled-field"}
                                    required
                                  />
                                </div>
                              </>
                            )}
                            <div className={`pr-0 pr-lg-3 col-12 col-lg-${vinculada ? '2' : '4'}`}>
                              <Field
                                component={DropdownListInput}
                                inputId="tipoRendimento"
                                name={`contaArrecadadoraList[${index}].tipoRendimento`}
                                label="Tipo de Rendimento"
                                options={tipoRendimentoList}
                                textField="value"
                                valueField="key"
                                filter="contains"
                                readOnly={readOnly}
                                outerClassName={readOnly && "disabled-field"}
                                required
                              />
                            </div>
                            <div className={`pr-0 pr-lg-3 col-12 col-lg-${vinculada ? '4' : '5'}`}>
                              <Field
                                component={MultSelectListInput}
                                inputId="contaArrecadadoraOrigemRecurso"
                                name={`contaArrecadadoraList[${index}].contaArrecadadoraOrigemRecurso`}
                                label="Origem dos Recursos"
                                options={origemRecursosList}
                                dataKey='id'
                                textField="descricao"
                                valueField="id"
                                filter="contains"
                                readOnly={readOnly}
                                outerClassName={readOnly && "disabled-field"}
                                className={readOnly && 'clear-inside'}
                              />
                            </div>

                            <div className={`pr-0 pr-lg-3 col-12 col-lg-4`}>
                              <Field
                                component={DropdownListInput}
                                inputId="vanPagamento"
                                name={`contaArrecadadoraList[${index}].vanPagamento`}
                                label="Relacionamento VAN Pagamento"
                                options={relacionamentoList}
                                textField="value"
                                valueField="key"
                                filter="contains"
                                readOnly={readOnly}
                                outerClassName={readOnly && "disabled-field"}
                                required
                              />
                            </div>

                            <div className={`pr-0 pr-lg-3 col-12 col-lg-4`}>
                              <Field
                                component={DropdownListInput}
                                inputId="vanCobranca"
                                name={`contaArrecadadoraList[${index}].vanCobranca`}
                                label="Relacionamento VAN Cobrança"
                                options={relacionamentoList}
                                textField="value"
                                valueField="key"
                                filter="contains"
                                readOnly={readOnly}
                                outerClassName={readOnly && "disabled-field"}
                                required
                              />
                            </div>

                            <div className={`pr-0 pr-lg-3 col-12 col-lg-4`}>
                              <Field
                                component={DropdownListInput}
                                inputId="vanConciliacao"
                                name={`contaArrecadadoraList[${index}].vanConciliacao`}
                                label="Relacionamento VAN Conciliação"
                                options={relacionamentoList}
                                textField="value"
                                valueField="key"
                                filter="contains"
                                readOnly={readOnly}
                                outerClassName={readOnly && "disabled-field"}
                                required
                              />
                            </div>
                          </div>

                          {!readOnly && (
                            <div className="col-12 col-lg-1 d-flex align-items-center">
                              <Button
                                classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize"
                                activeClassName={"col-lg-auto p-0"}
                                buttonStyle={"success"}
                                action={() => this.removeContaArrecadadora(index)}
                                icon={"trash"}
                                name={"Remover"}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
            </div>
            <If test={!readOnly}>
              <div className="mx-auto mt-3">
                <Button
                  classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                  activeClassName={"col-lg-auto p-0"}
                  buttonStyle={"success"}
                  action={() =>
                    this.addContaArrecadadora(contaArrecadadoraList.length)
                  }
                  icon={"plus"}
                  name={"Adicionar"}
                />
              </div>
            </If>
          </div>
        </div>
      </div>
    );

    let passivoPrePagamento = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Pré-Pagamento</span>
          <div className="d-flex w-100">
            <div className="col more-fields pl-0">
              <div className="row">
                <div className="col-lg-4 col-xl-3">
                  <Field
                    component={DropdownListInput}
                    inputId="existenciaPrePagamento"
                    name="existenciaPrePagamento"
                    label="Pré-Pagamento"
                    readOnly={readOnly}
                    onChange={() => {
                      limpaDependenteOnChange("formaPrePagamento", "");
                      limpaDependenteOnChange("possibilidadePrePagamento", "");
                      limpaDependenteOnChange("dataLockup", "");
                      limpaDependenteOnChange("opcaoResgateSecuritizadora", "");
                      limpaDependenteOnChange("clausulaContrato", "");
                    }}
                    outerClassName={readOnly && "disabled-field"}
                    required
                  />
                </div>

                {!gestora && existenciaPrePagamento == "Sim" && (
                  <>
                    <div className="col-lg-4 col-xl-3">
                      <Field
                        component={DropdownListInput}
                        inputId="formaPrePagamento"
                        name="formaPrePagamento"
                        label="Condição"
                        options={formaPrePagamentoList}
                        textField="value"
                        valueField="key"
                        filter="contains"
                        outerClassName={readOnly && "disabled-field"}
                        readOnly={readOnly}
                        required
                      />
                    </div>

                    <div className="col-lg-4 col-xl-3">
                      <Field
                        component={DropdownListInput}
                        inputId="possibilidadePrePagamento"
                        name="possibilidadePrePagamento"
                        label="Modalidade"
                        options={possibilidadePrePagamentoList}
                        textField="value"
                        valueField="key"
                        filter="contains"
                        outerClassName={readOnly && "disabled-field"}
                        readOnly={readOnly}
                        required
                      />
                    </div>

                    <div className="col-lg-4 col-xl-3">
                      <Field
                        component={Input}
                        inputId="dataLockup"
                        name="dataLockup"
                        type="date"
                        label="Data de lockup"
                        outerClassName={readOnly && "disabled-field"}
                        readOnly={readOnly}
                        required
                      />
                    </div>

                    <div className="col-lg-4 col-xl-3">
                      <Field
                        component={DropdownListInput}
                        inputId="opcaoResgateSecuritizadora"
                        name="opcaoResgateSecuritizadora"
                        label="Resgate pela Emissora"
                        outerClassName={readOnly && "disabled-field"}
                        readOnly={readOnly}
                        required
                      />
                    </div>
                  </>
                )}

                {existenciaPrePagamento == "Sim" && (
                  <div className="col-lg-4 col-xl-3">
                    <Field
                      component={Input}
                      inputId="clausulaContrato"
                      name="clausulaContrato"
                      type="text"
                      label="Referência Contrato"
                      outerClassName={readOnly && "disabled-field"}
                      readOnly={readOnly}
                      required
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="d-flex flex-column mt-4">
        {!gestora && passivoControle}

        {blocoModular1}

        {!gestora && passivoDatasPrecos}

        {passivoCodigos}

        {ratingOperacao}

        {!gestora && passivoContasFiduciarias}

        {blocoModular2}
        
        {passivoPrePagamento}
      </div>
    );
  }
}

const selector = formValueSelector("passivoForm");

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      arrayInsert,
      arrayRemove,
      change,
      inserirNomePastaCedoc,
      changeDataEmissao,
      changeMoeda,
      loadFormPassivoPatrimonio,
      // 
      loadDataFundo,
      loadClassesFundo,
      limpaDependenteOnChange,
    },
    dispatch
  );

// prettier-ignore
const mapStateToProps = (state) => {
  return {
    codigoPatrimonio: selector(state, "codigoPatrimonio"),
    possuiPerfilAnalista: selector(state, "possuiPerfilAnalista"),
    agendaRatingsList: selector(state, "agendaRatingsList"),
    contaArrecadadoraList: selector(state, "contaArrecadadoraList") || [],
    premioPrePagamentoList: selector(state, "premioPrePagamentoList") || [],
    agencia: selector(state, "agencia") || {},
    existenciaPrePagamento: selector(state, "existenciaPrePagamento") || [],
    quantidadeEmitidaSelector: selector(state, "quantidadeEmitida"),
    precoUnitarioSelector: selector(state, "precoUnitario"),
    quantidadeDistribuidaSelector: selector(state, "quantidadeDistribuida"),
    totalEmitidoSelector: selector(state, "totalEmitido"),
    totalDistribuidoSelector: selector(state, "totalDistribuido"),
    seloPassivoOperacoes: selector(state, "seloPassivoOperacoes"),
    nomePasta: selector(state, "NomePasta"),
    cedocPasta: selector(state, "cedoc") || [],
    statusOperacaoPassivo: selector(state, "statusOperacaoPassivo") || null,
    tipoOferta: selector(state, "tipoOferta") || null,
    cedoc: state.passivoReducer.passivoFormTypes.idCedoc,
    tituloList: state.passivoReducer.passivoFormTypes.tituloList,
    gestorFiduciarioList: state.passivoReducer.passivoFormTypes.gestorFiduciarioList,
    coordenadorLiderList: state.passivoReducer.passivoFormTypes.coordenadorLiderList,
    assessorLegalList: state.passivoReducer.passivoFormTypes.assessorLegalList,
    escrituradorList: state.passivoReducer.passivoFormTypes.escrituradorList,
    agenteFiduciarioList: state.passivoReducer.passivoFormTypes.agenteFiduciarioList,
    agentePagamentoList: state.passivoReducer.passivoFormTypes.agentePagamentoList,
    camaraLiquidacaoList: state.passivoReducer.passivoFormTypes.camaraLiquidacaoList,
    moedasList: state.passivoReducer.passivoFormTypes.moedas,
    bancoLiquidanteList: state.passivoReducer.passivoFormTypes.bancoLiquidanteList,
    classeList: state.passivoReducer.passivoFormTypes.classeList,
    naturezaList: state.passivoReducer.passivoFormTypes.naturezaList,
    tipoOfertaList: state.passivoReducer.passivoFormTypes.tipoOfertaList,
    formaCalculoList: state.passivoReducer.passivoFormTypes.formaCalculoList,
    concentracaoList: state.passivoReducer.passivoFormTypes.concentracaoList,
    possibilidadePrePagamentoList: state.passivoReducer.passivoFormTypes.possibilidadePrePagamentoList,
    formaPrePagamentoList: state.passivoReducer.passivoFormTypes.formaPrePagamentoList,
    tipoContaArrecadadoraList: state.passivoReducer.passivoFormTypes.tipoContaArrecadadoraList,
    origemRecursosList: state.passivoReducer.passivoFormTypes.origemRecursos || [],
    tipoRendimentoList: state.passivoReducer.passivoFormTypes.tipoRendimentoEnum,
    bancoList: state.passivoReducer.passivoFormTypes.bancoList,
    emissorList: state.passivoReducer.passivoFormTypes.emissorList,
    statusOperacaoPassivoList: state.passivoReducer.passivoFormTypes.statusOperacaoPassivoList || [],
    patrimonioSeparadoList: state.passivoReducer.passivoFormTypes.patrimonioSeparadoList || [],
    divulgacaoList: state.passivoReducer.passivoFormTypes.divulgacaoList || [],
    produtoList: state.passivoReducer.passivoFormTypes.produtoPassivoList || [],
    usuarioList: state.passivoReducer.passivoFormTypes.usuarioList || [],
    usuarioEstruturacaoList: state.passivoReducer.passivoFormTypes.usuariosEstruturacao || [],
    selosList: state.passivoReducer.passivoFormTypes.selos || [],
    relacionamentoList: state.passivoReducer.passivoFormTypes.relacionamentoList || [],
    tipoInvestidorList: state.passivoReducer.passivoFormTypes.tipoInvestidorList || [],
    passivoOperacao: state.passivoReducer.passivoFormTypes.passivoOperacao || [],
    moedaDinamica: state.passivoReducer.moedaDinamica || null,
    // 
    gestora: currEnv == process.env.REACT_APP_ENV_GEST_ID,
    // 
    possuiMultiGestao: selector(state, "possuiMultiGestao"),
    fundoList: state.passivoReducer.passivoFormTypes.fundos || [],
    classesFundoList: state.passivoReducer.passivoFormTypes.classesFundoList || [],
    classesLoaded: state.passivoReducer.passivoFormTypes.classesLoaded || false,
    subclassesFundoList: state.passivoReducer.passivoFormTypes.subclassesFundoList || [],
    subclassesLoaded: state.passivoReducer.passivoFormTypes.subclassesLoaded || false,
    administradorFiduciarios: state.passivoReducer.passivoFormTypes.administradorFiduciarios || [],
    gestoras: state.passivoReducer.passivoFormTypes.gestoras || [],
    gestorasII: state.passivoReducer.passivoFormTypes.gestorasII || [],
    consultorCreditos: state.passivoReducer.passivoFormTypes.consultorCreditos || [],
    controladorias: state.passivoReducer.passivoFormTypes.controladorias || [],
    distribuidores: state.passivoReducer.passivoFormTypes.distribuidores || [],
    custodiantes: state.passivoReducer.passivoFormTypes.custodiantes || [],
    agenteMonitoramentos: state.passivoReducer.passivoFormTypes.agenteMonitoramentos || [],
    agenteFormalizacoes: state.passivoReducer.passivoFormTypes.agenteFormalizacoes || [],
    agenteCobrancaJudiciais: state.passivoReducer.passivoFormTypes.agenteCobrancaJudiciais || [],
    agenteCobrancaExtrajudiciais: state.passivoReducer.passivoFormTypes.agenteCobrancaExtrajudiciais || [],
    auditorias: state.passivoReducer.passivoFormTypes.auditorias || [],
    agenciaRatings: state.passivoReducer.passivoFormTypes.agenciaRatings || [],
    operacao: state.passivoReducer.passivoFormTypes?.passivoOperacao?.operacao || {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PassivoFormGeral);
