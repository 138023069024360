/* eslint eqeqeq: 0 */

import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, change } from "redux-form";
import { bindActionCreators } from "redux";
import { limpaDependentesOnChange, mudaDependenteOnChange, resetListContaOrigemOrig } from "../../../../../../actions/patrimonio/PatrimonioAction";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import {
  getOperacoes,
  selectOperacaoNoForm,
} from "../../../../../../actions/operacao/OperacaoAction";
import { loadContaOrigem } from "../../../../../../actions/patrimonio/PatrimonioAction";
import GenericPickerInput from "../../../../../common/input/GenericPickerInput";

const PatrimonioFormOriginacao = (props) => {
  const {
    view,
    showing,
    readOnly,
    operacao,
    getOperacoes,
    operacoesList,
    contaOrigemList,
    loadContaOrigem,
    possuiIntegracao,
    origemRecursoList,
    idPatrimonioSeparado,
    mudaDependenteOnChange,
    resetListContaOrigemOrig,
    limpaDependentesOnChange,
  } = props;

  const temIntegracao =
    (typeof possuiIntegracao == "boolean" && possuiIntegracao) ||
    possuiIntegracao == "Sim";

  const rOnly = (readOnly || !temIntegracao)

  return (
    <div className="column" id="ptr-orig">
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4 mt-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">
            Configurações Originação
          </span>
          <div className="row">
            <div className="col-lg">
              <Field
                component={DropdownListInput}
                inputId="originacao.possuiIntegracao"
                name="originacao.possuiIntegracao"
                label="Integração com Originação?"
                readOnly={readOnly}
                required
                onChange={(_e, v) => {
                  limpaDependentesOnChange([
                    "originacao.operacao",
                    "originacao.contaOrigem",
                    "originacao.origemRecurso",
                  ]);

                  resetListContaOrigemOrig()
                }}
              />
            </div>

            <div className="col-lg">
              <div className="mt-2 d-flex flex-column mb-lg-0 mb-3">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Série
                </label>
                <Field
                  required
                  isClearable
                  value={operacao}
                  options={operacoesList}
                  name={"originacao.operacao"}
                  inputId="originacao.operacao"
                  component={GenericPickerInput}
                  readOnly={rOnly}
                  loadData={(v) => getOperacoes(v, [{ key: idPatrimonioSeparado }])}
                  noOptionsMessage="Nenhum registro encontrado"
                  placeholder={"Pesquisar série ex.:CRI.1.SEC.2"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  activeClassName={`inputPgmt ${rOnly ? "input-async disabled" : ""}`}
                  input={{
                    value: operacao,
                    onChange: (v) => {
                      if (v?.key) loadContaOrigem(idPatrimonioSeparado, v?.key);
                      mudaDependenteOnChange("originacao.contaOrigem")
                      mudaDependenteOnChange("originacao.operacao", v)
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg">
              <Field
                required
                valueField="id"
                component={DropdownListInput}
                inputId="originacao.contaOrigem"
                name="originacao.contaOrigem"
                options={contaOrigemList}
                textField="nomeExibicao"
                filter="contains"
                label="Conta Origem"
                readOnly={rOnly}
              />
            </div>

            <div className="col-lg">
              <Field
                component={DropdownListInput}
                inputId="originacao.origemRecurso"
                name="originacao.origemRecurso"
                label="Origem dos Recursos"
                options={origemRecursoList}
                textField="descricao"
                valueField="id"
                filter="contains"
                readOnly={rOnly}
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const selector = formValueSelector("patrimonioForm");

const mapStateToProps = (state) => ({
  operacoesList: state.operacaoReducer.operacoes || [],
  contaOrigemList:
    state.patrimonioReducer.patrimonioFormTypes.contaOrigemList || [],
  origemRecursoList:
    state.patrimonioReducer.patrimonioFormTypes.origemRecursoList || [],
  operacao: selector(state, "originacao.operacao") || null,
  possuiIntegracao: selector(state, "originacao.possuiIntegracao"),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOperacoes,
      loadContaOrigem,
      selectOperacaoNoForm,
      mudaDependenteOnChange,
      resetListContaOrigemOrig,
      limpaDependentesOnChange,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatrimonioFormOriginacao);
