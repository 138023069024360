/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import ContasReceberForm from "../../contasReceber/pages/form/ContasReceberForm";
import {
  loadContasReceberForm,
  editContasReceber,
} from "../../../../actions/contasReceber/ContasReceberAction";
import { authorizationFunction } from "../../../../actions/authorization/AuthorizationAction";
import { CONTAS_RECEBER, UPDATE } from "../../../../utils/actionsAndFeaturesAuth";

class ContasReceberUpdatePage extends Component {
  componentDidMount() {
    let idContasReceber = this.props.match.params.idContasReceber;
    const { authorizationFunction, loadContasReceberForm } = this.props;

    async function fetchLoad() {
      await authorizationFunction(
        { idAcao: UPDATE, idFuncionalidade: CONTAS_RECEBER, validarAreaAtuacao: true },
        () => loadContasReceberForm(idContasReceber)
      );
    }
  
    fetchLoad();
  }

  render() {
    const { editContasReceber, contaReceber } = this.props;

    const statusValue = contaReceber?.statusAprovacao?.key;
    const identificador = contaReceber?.identificador;

    const blockeds = [2, 3, 4, 5];

    const readOnly = blockeds.some((b) => b == statusValue);

    return (
      <ContasReceberForm
        title={`Editar - Contas a Receber ${identificador ? `- ${identificador}` : ""}`}
        onSubmit={editContasReceber}
        readOnly={readOnly}
        floatButtonVisible={true}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  contaReceber: state.contasReceberReducer.contasReceberFormTypes.contaReceber || {},
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      editContasReceber,
      loadContasReceberForm,
      authorizationFunction,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(ContasReceberUpdatePage)
);
