import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import {
    showReceitaForm,
    getReceitas,
    removeReceita,
    showReceitaDeleteDialog,
    goToFormPage,
    getFiltrosReceita
} from '../../../../../../actions/operacao/ReceitaAction';

import Table from '../../../../../common/table/Table';
import TableHeader from '../../../../../common/table/TableHeader';
import TableContent from '../../../../../common/table/TableContent';
import FloatButton from '../../../../../common/button/FloatButton';
import If from '../../../../../common/If/If';
import TableEmptyData from '../../../../../common/table/TableEmptyData';
import PaginationRB from '../../../../../common/pagination/PaginationRB';
import TableActionWithRouteAuthorization from '../../../../../common/table/TableActionWithRouteAuthorization';
import { authorizationFunction } from '../../../../../../actions/authorization/AuthorizationAction';
import { OPERACAO_RECEITAS, CREATE, DELETE, READ } from '../../../../../../utils/actionsAndFeaturesAuth';
import { getDateFormatedFromString } from '../../../../../../utils/helpers/DateTimeHelper';
import { convertCurrencyNumber } from '../../../../../../utils/helpers/NumberHelper';
import { Field, reduxForm } from 'redux-form';
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import Input from "../../../../../common/input/Input";
import Button from "../../../../../common/button/Button";
import ModalAction from '../../../../../common/modal/ModalAction';

let ReceitaList = ({
    operacoes,
    receitaData,
    messages,
    removeReceita,
    showReceitaForm,
    getReceitas,
    showReceitaDeleteDialog,
    showDeleteDialog,
    goToFormPage,
    authorizationFunction,
    filtros,
    getFiltrosReceita
}) => {
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);

    const getIdsOperacao = () => Array.isArray(operacoes) && operacoes.length ? operacoes.map((o) => o.key) : 0;

    useEffect(() => {
      const fetchData = async () => {
        await getFiltrosReceita();
        getReceitas(getIdsOperacao());
      };

      fetchData();
    }, []);
    
    useEffect(() => {
        isInitialLoad ? setIsInitialLoad(false) : getReceitas(getIdsOperacao());
    }, [operacoes]);

    const idsOperacoes = operacoes.map(o => o.key);
    const emptyData = messages?.infos?.[0];

    const tableHeader = (
        <tr>
            <th className='text-center fw-700'>Cód. Opea</th>
            <th className='text-center fw-700'>Identificador</th>
            <th className='text-center fw-700'>Tipo Receita</th>
            <th className='text-center fw-700'>Tomador</th>
            <th className='text-center fw-700'>Data Início</th>
            <th className='text-center fw-700'>Data Vencimento</th>
            <th className='text-center fw-700'>Valor Atual Recorrente</th>
            <th className='text-center fw-700'>Status</th>
            <th className="text-center"></th>
        </tr>
    );

    const tableContent = receitaData?.hasItems
        ? receitaData.items.map(receita => (
            <tr key={receita.idReceitaContrato}>
                <td className='text-center'>{receita?.codigoOpea || '--'}</td>
                <td className='text-center'>{receita?.identificador || '--'}</td>
                <td className='text-center'>{receita?.tipoReceita || '--'}</td>
                <td className='text-center'>{receita?.tomador || '--'}</td>
                <td className='text-center'>{receita.dataInicio ? getDateFormatedFromString(receita?.dataInicio) : '--'}</td>
                <td className='text-center'>{receita?.dataVencimento ? getDateFormatedFromString(receita?.dataVencimento) : '--'}</td>
                <td className='text-center'>{receita?.valorAtualRecorrente ? convertCurrencyNumber(receita?.valorAtualRecorrente, 'R$ ', 2) : '--'}</td>
                <td className='text-center'>{receita?.statusAprovacao?.value || '--'}</td>
                <TableActionWithRouteAuthorization
                    space="2"
                    feature={OPERACAO_RECEITAS}
                    data={{ validarAreaAtuacao: true }}
                    updateAction={() => goToFormPage(true, receita.idReceitaContrato)}
                    viewAction={() => goToFormPage(false, receita.idReceitaContrato)}
                    remove={() => showReceitaDeleteDialog(true, receita.idReceitaContrato)}
                />
            </tr>
        ))
        : <TableEmptyData message={emptyData} />;

    return (
        <div>
            <div className="mx-2 shadow-custom bg-white rounded-12 py-1 d-flex flex-lg-row flex-column align-items-center px-lg-0 mb-3">
                <div className="col-lg">
                    <Field
                        component={DropdownListInput}
                        inputId="statusAprovacao"
                        name="statusAprovacao"
                        label="Status"
                        textField="value"
                        valueField="key"
                        filter="contains"
                        options={filtros?.statusList || []}
                    />
                </div>
                <div className="col-lg">
                    <Field
                        component={DropdownListInput}
                        inputId="tomador"
                        name="tomador"
                        label="Tomador"
                        textField="razaoSocialComCnpj"
                        valueField="id"
                        filter="contains"
                        options={filtros?.tomadorList || []}
                    />
                </div>
                <div className="col-lg">
                    <Field
                        component={Input}
                        inputId="identificador"
                        name="identificador"
                        label="Identificador"
                    />
                </div>
                <div className="col-lg">
                    <Field
                        component={DropdownListInput}
                        inputId="tipoReceita"
                        name="tipoReceita"
                        label="Tipo de Receita"
                        textField="nome"
                        valueField="id"
                        filter="contains"
                        options={filtros?.tipoReceitaList || []}
                    />
                </div>
                <div className="col-lg-2 pb-lg-0 pb-3 mb-2p">
                    <Button
                        classes="rounded text-white p-bg-imp mb-0 h-38 px-4 py-1 w-100"
                        action={() => authorizationFunction({ idAcao: READ, idFuncionalidade: OPERACAO_RECEITAS }, ()=> getReceitas(getIdsOperacao()))}
                        name="Buscar"
                    />
                </div>
            </div>
            {receitaData?.hasItems && (
                <div className="d-flex flex-column mb-3">
                    <div className="mx-2 shadow-custom bg-white rounded-12">
                        <p className="page-head__title f-28 mb-0 px-3 mt-4 mb-3">Receitas das Operações</p>
                        <Table
                            withoutHeader
                            classes="card col-12 shadow-none bg-white py-0 m-0 mb-2 rounded-12"
                            totalItems={receitaData.totalCount}
                            pagination={
                                <PaginationRB
                                    pageNumber={receitaData.pageIndex - 1}
                                    itemsPerPage={receitaData.pageSize}
                                    totalItems={receitaData.totalCount}
                                    filter={idsOperacoes}
                                    fetchFunction={getReceitas}
                                    pageRange={5}
                                />
                            }
                        >
                            <TableHeader>
                                {tableHeader}
                            </TableHeader>
                            <TableContent>
                                {tableContent}
                            </TableContent>
                        </Table>
                    </div>
                </div>
            )}

            <If test={operacoes?.length === 1}>
                <FloatButton
                    activeClassName={"float"}
                    title="Novo"
                    icon={"fa fa-plus my-float"}
                    action={
                        () => authorizationFunction(
                            { idAcao: CREATE, idFuncionalidade: OPERACAO_RECEITAS },
                            () => showReceitaForm(operacoes[0].key)
                        )
                    }
                />
            </If>

            <ModalAction
                title="Aviso"
                open={showDeleteDialog.showDialog}
                close={() => showReceitaDeleteDialog(false)}
                message="Excluindo receita selecionada"
                action={
                    () => authorizationFunction(
                        { idAcao: DELETE, idFuncionalidade: OPERACAO_RECEITAS },
                        () => removeReceita(showDeleteDialog.idReceita, idsOperacoes)
                    )
                }
            />
        </div>
    );
};

ReceitaList = reduxForm({ 
    form: "receitaListForm",
})(ReceitaList);

const mapStateToProps = state => ({
    showDeleteDialog: state.receitaReducer.showReceitaDeleteDialog,
    receitaData: state.receitaReducer.receitas,
    messages: state.receitaReducer.messages,
    operacoes: state.operacaoReducer.selectedOperacoes,
    filtros: state.receitaReducer.filtrosReceita || {},
});
const mapDispacthToProps = dispatch => bindActionCreators({
    getReceitas,
    getFiltrosReceita,
    removeReceita,
    showReceitaForm,
    showReceitaDeleteDialog,
    goToFormPage,
    change,
    authorizationFunction,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(ReceitaList));
