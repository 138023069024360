/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, getFormValues, change } from "redux-form";
import MaskedInput from "../../../../../common/input/MaskedInput";
import PercentageInput from "../../../../../common/input/PercentageInput";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import Input from "../../../../../common/input/Input";
import MaskedFormatInput from "../../../../../common/input/MaskedFormatInput";
import { CURRENCY, customFormatCnpjCpf } from "../../../../../../utils/helpers/StringHelper";
import { convertCurrencyToNumber } from "../../../../../../utils/helpers/NumberHelper";

const Credito = (props) => {
  const { type, readOnly, manual, periodicidadeDeAvaliacaoDasGarantisList, formValues, change } = props;

  const periodicidadeList = periodicidadeDeAvaliacaoDasGarantisList.map((item, i) => ({ value: item, key: i+1 }))

  const { credito } = formValues

  const aVencerPorNatureza = {
    CRI: [
      credito.incorporacaoImobiliaria, credito.alugueis, credito.aquisiscaoDeImoveis, credito.loteamento, credito.multipropriedade, credito.homeEquity, credito.outros
    ],
    CRA: [
      credito.producaoDeProdutosAgropecuarios, credito.comercializacaoDeProdutosAgropecuarios, credito.beneficiamentoDeProdutosAgropecuarios, 
      credito.industrializacaoDeProdutosAgropecuarios, credito.producaoDeInsumosAgropecuarios, credito.comercializacaoDeInsumosAgropecuarios, 
      credito.beneficiamentoDeInsumosAgropecuarios, credito.industrializacaoDeInsumosAgropecuarios, credito.producaoDeMaquinasEImplementos, 
      credito.comercializacaoDeMaquinasEImplementos, credito.beneficiamentoDeMaquinasEImplementos, credito.industrializacaoDeMaquinasEImplementos
    ]
  };

  const aVencerPorPrazo = [
    credito.aVencerAte30Dias, credito.aVencerDe31a60Dias, credito.aVencerDe61a90Dias, credito.aVencerDe91a120Dias,
    credito.aVencerDe121a150Dias, credito.aVencerDe151a180Dias, credito.aVencerDe181a360Dias, credito.aVencerAcimaDe361Dias
  ]

  const vencidosENaoPagos = [
    credito.vencidosAte30Dias, credito.vencidosDe31a60Dias, credito.vencidosDe61a90Dias, credito.vencidosDe91a120Dias, 
    credito.vencidosDe121a150Dias, credito.vencidosDe151a180Dias, credito.vencidosDe181a360Dias, credito.vencidosAcimaDe361Dias
  ]
  
  // SOMA "A VENCER POR NATUREZA"
  React.useEffect(() => {
    const total = aVencerPorNatureza[type]?.flatMap(v => convertCurrencyToNumber(v || 0)).reduce((acc, curr) => acc + curr, 0);
    change("RegulatorioForm", "credito.aVencerPorNatureza", total || 0);
  }, [...aVencerPorNatureza.CRI, ...aVencerPorNatureza.CRA]);

  // SOMA "A VENCER POR PRAZO"
  React.useEffect(() => {
    const total = aVencerPorPrazo?.flatMap(v => convertCurrencyToNumber(v || 0)).reduce((acc, curr) => acc + curr, 0);
    change("RegulatorioForm", "credito.aVencerPorPrazo", total || 0);
  }, [...aVencerPorPrazo]);

  // SOMA "VENCIDOS E NÃO PAGOS"
  React.useEffect(() => {
    const total = vencidosENaoPagos?.flatMap(v => convertCurrencyToNumber(v || 0)).reduce((acc, curr) => acc + curr, 0);
    change("RegulatorioForm", "credito.vencidosENaoPagos", total || 0);
  }, [...vencidosENaoPagos]);

  const arrCredito1CRA = [
    { num: "12.1", label: "Valor total das parcelas em atraso", id: "credito.valorTotalDasParcelasEmAtraso", readOnly: readOnly, currency: true },
    { num: "12.2", label: "A vencer por natureza", id: "credito.aVencerPorNatureza", readOnly: true, currency: true },
    { num: "12.2.1", label: "Produção de Produtos Agropecuários", id: "credito.producaoDeProdutosAgropecuarios", readOnly: readOnly, currency: true },
    { num: "12.2.2", label: "Comercialização de Produtos Agropecuários", id: "credito.comercializacaoDeProdutosAgropecuarios", readOnly: readOnly, currency: true },
    { num: "12.2.3", label: "Beneficiamento de Produtos Agropecuários", id: "credito.beneficiamentoDeProdutosAgropecuarios", readOnly: readOnly, currency: true },
    { num: "12.2.4", label: "Industrialização de Produtos Agropecuários", id: "credito.industrializacaoDeProdutosAgropecuarios", readOnly: readOnly, currency: true },
    { num: "12.2.5", label: "Produção de Insumos Agropecuários", id: "credito.producaoDeInsumosAgropecuarios", readOnly: readOnly, currency: true },
    { num: "12.2.6", label: "Comercialização de Insumos Agropecuários", id: "credito.comercializacaoDeInsumosAgropecuarios", readOnly: readOnly, currency: true },
    { num: "12.2.7", label: "Beneficiamento de Insumos Agropecuários", id: "credito.beneficiamentoDeInsumosAgropecuarios", readOnly: readOnly, currency: true },
    { num: "12.2.8", label: "Industrialização de Insumos Agropecuários", id: "credito.industrializacaoDeInsumosAgropecuarios", readOnly: readOnly, currency: true },
    { num: "12.2.9", label: "Produção de Máquinas e Implementos", id: "credito.producaoDeMaquinasEImplementos", readOnly: readOnly, currency: true },
    { num: "12.2.10", label: "Comercialização de Máquinas e Implementos", id: "credito.comercializacaoDeMaquinasEImplementos", readOnly: readOnly, currency: true },
    { num: "12.2.11", label: "Beneficiamento de Máquinas e Implementos", id: "credito.beneficiamentoDeMaquinasEImplementos", readOnly: readOnly, currency: true },
    { num: "12.2.12", label: "Industrialização de Máquinas e Implementos", id: "credito.industrializacaoDeMaquinasEImplementos", readOnly: readOnly, currency: true },
    { num: "12.3", label: "Concentração", id: "credito.concentracao", readOnly: true },
    { num: "12.4", label: "A vencer por prazo", id: "credito.aVencerPorPrazo", readOnly: true, currency: true },
    { num: "12.4.1", label: "Até 30 dias", id: "credito.aVencerAte30Dias", readOnly: readOnly, currency: true },
    { num: "12.4.2", label: "De 31 a 60 dias", id: "credito.aVencerDe31a60Dias", readOnly: readOnly, currency: true },
    { num: "12.4.3", label: "De 61 a 90 dias", id: "credito.aVencerDe61a90Dias", readOnly: readOnly, currency: true },
    { num: "12.4.4", label: "De 91 a 120 dias", id: "credito.aVencerDe91a120Dias", readOnly: readOnly, currency: true },
    { num: "12.4.5", label: "De 121 a 150 dias", id: "credito.aVencerDe121a150Dias", readOnly: readOnly, currency: true },
    { num: "12.4.6", label: "De 151 a 180 dias", id: "credito.aVencerDe151a180Dias", readOnly: readOnly, currency: true },
    { num: "12.4.7", label: "De 181 a 360 dias", id: "credito.aVencerDe181a360Dias", readOnly: readOnly, currency: true },
    { num: "12.4.8", label: "Acima de 361 dias", id: "credito.aVencerAcimaDe361Dias", readOnly: readOnly, currency: true },
    { num: "12.5", label: "Vencidos e não pagos", id: "credito.vencidosENaoPagos", readOnly: true, currency: true },
    { num: "12.5.1", label: "Até 30 dias", id: "credito.vencidosAte30Dias", readOnly: readOnly, currency: true },
    { num: "12.5.2", label: "De 31 a 60 dias", id: "credito.vencidosDe31a60Dias", readOnly: readOnly, currency: true },
    { num: "12.5.3", label: "De 61 a 90 dias", id: "credito.vencidosDe61a90Dias", readOnly: readOnly, currency: true },
    { num: "12.5.4", label: "De 91 a 120 dias", id: "credito.vencidosDe91a120Dias", readOnly: readOnly, currency: true },
    { num: "12.5.5", label: "De 121 a 150 dias", id: "credito.vencidosDe121a150Dias", readOnly: readOnly, currency: true },
    { num: "12.5.6", label: "De 151 a 180 dias", id: "credito.vencidosDe151a180Dias", readOnly: readOnly, currency: true },
    { num: "12.5.7", label: "De 181 a 360 dias", id: "credito.vencidosDe181a360Dias", readOnly: readOnly, currency: true },
    { num: "12.5.8", label: "Acima de 361 dias", id: "credito.vencidosAcimaDe361Dias", readOnly: readOnly, currency: true },
    { num: "12.6", label: "Pré-pagamentos no período", id: "credito.prePagamentosNoPeriodo", readOnly: readOnly, currency: true },
    { num: "12.6.1", label: "Montante recebido no período", id: "credito.montanteRecebidoNoPeriodo", readOnly: readOnly, currency: true },
    { num: "12.6.2", label: "Informações impacto", id: "credito.informacoesImpacto", readOnly: readOnly },
    { num: "12.7.1", label: "Valor dívidas adquiridas", id: "credito.valorDividasAdquiridas", readOnly: true, currency: true },
    { num: "12.7.2", label: "% créditos cobertos retenção de risco", id: "credito.creditosCobiertosRetencaoDeRisco", readOnly: true, percent: true },
    { num: "12.7.3", label: "% créditos cobertos retenção outros", id: "credito.creditosCobiertosRetencaoOutros", readOnly: true, percent: true },
    { num: "12.7.4", label: "Valor garantias / Valor carteira", id: "credito.valorGarantisValorCarteira", readOnly: readOnly, percent: true },
    { num: "12.7.5", label: "Periodicidade de avaliação das garantias", id: "credito.periodicidadeDeAvaliacaoDasGarantis", readOnly: readOnly, dropdown: true, options: periodicidadeList },
    { num: "12.7.6", label: "Duration da carteira", id: "credito.durationDaCarteira", readOnly: readOnly },
    { num: "12.7.7", label: "Valor total créditos / Valor emissão", id: "credito.valorTotalCreditosValorEmissao", readOnly: readOnly, percent: true },
    { num: "12.7.8", label: "Outras considerações relevantes", id: "credito.outrasConsideracoesRelevantes", readOnly: readOnly },
    { num: "12.8", label: "Concentração Devedores" },
    { num: "12.8.1", label: "Maior Devedor", id: "credito.maiorDevedor", readOnly: readOnly, percent: true },
    { num: "12.8.2", label: "5 Maiores Devedores", id: "credito.cincoMaioresDevedores", readOnly: readOnly, percent: true },
    { num: "12.8.3", label: "10 Maiores Devedores", id: "credito.dezMaioresDevedores", readOnly: readOnly, percent: true },
    { num: "12.8.4", label: "20 Maiores Devedores", id: "credito.vinteMaioresDevedores", readOnly: readOnly, percent: true },
    { num: "12.9", label: "Devedores > 20%" },
  ];

  const arrCredito1CRI = [
    { num: "12.1", label: "Valor total das parcelas em atraso", id: "credito.valorTotalDasParcelasEmAtraso", readOnly: readOnly, currency: true },
    { num: "12.2", label: "Concentração", id: "credito.concentracao", readOnly: true },
    { num: "12.3", label: "A vencer por natureza", id: "credito.aVencerPorNatureza", readOnly: true, currency: true },
    { num: "12.3.1", label: "Incorporação imobiliária", id: "credito.incorporacaoImobiliaria", readOnly: ((!readOnly && !manual) || readOnly), currency: true },
    { num: "12.3.2", label: "Aluguéis", id: "credito.alugueis", readOnly: ((!readOnly && !manual) || readOnly), currency: true },
    { num: "12.3.3", label: "Aquisição de imóveis", id: "credito.aquisiscaoDeImoveis", readOnly: ((!readOnly && !manual) || readOnly), currency: true },
    { num: "12.3.4", label: "Loteamento", id: "credito.loteamento", readOnly: ((!readOnly && !manual) || readOnly), currency: true },
    { num: "12.3.5", label: "Multipropriedade", id: "credito.multipropriedade", readOnly: ((!readOnly && !manual) || readOnly), currency: true },
    { num: "12.3.6", label: "Home Equity", id: "credito.homeEquity", readOnly: ((!readOnly && !manual) || readOnly), currency: true },
    { num: "12.3.7", label: "Outros", id: "credito.outros", readOnly: ((!readOnly && !manual) || readOnly), currency: true },
    { num: "12.4", label: "A vencer por prazo", id: "credito.aVencerPorPrazo", readOnly: true, currency: true },
    { num: "12.4.1", label: "Até 30 dias", id: "credito.aVencerAte30Dias", readOnly: readOnly, currency: true },
    { num: "12.4.2", label: "De 31 a 60 dias", id: "credito.aVencerDe31a60Dias", readOnly: readOnly, currency: true },
    { num: "12.4.3", label: "De 61 a 90 dias", id: "credito.aVencerDe61a90Dias", readOnly: readOnly, currency: true },
    { num: "12.4.4", label: "De 91 a 120 dias", id: "credito.aVencerDe91a120Dias", readOnly: readOnly, currency: true },
    { num: "12.4.5", label: "De 121 a 150 dias", id: "credito.aVencerDe121a150Dias", readOnly: readOnly, currency: true },
    { num: "12.4.6", label: "De 151 a 180 dias", id: "credito.aVencerDe151a180Dias", readOnly: readOnly, currency: true },
    { num: "12.4.7", label: "De 181 a 360 dias", id: "credito.aVencerDe181a360Dias", readOnly: readOnly, currency: true },
    { num: "12.4.8", label: "Acima de 361 dias", id: "credito.aVencerAcimaDe361Dias", readOnly: readOnly, currency: true },
    { num: "12.5", label: "Vencidos e não pagos", id: "credito.vencidosENaoPagos", readOnly: true, currency: true },
    { num: "12.5.1", label: "Até 30 dias", id: "credito.vencidosAte30Dias", readOnly: readOnly, currency: true },
    { num: "12.5.2", label: "De 31 a 60 dias", id: "credito.vencidosDe31a60Dias", readOnly: readOnly, currency: true },
    { num: "12.5.3", label: "De 61 a 90 dias", id: "credito.vencidosDe61a90Dias", readOnly: readOnly, currency: true },
    { num: "12.5.4", label: "De 91 a 120 dias", id: "credito.vencidosDe91a120Dias", readOnly: readOnly, currency: true },
    { num: "12.5.5", label: "De 121 a 150 dias", id: "credito.vencidosDe121a150Dias", readOnly: readOnly, currency: true },
    { num: "12.5.6", label: "De 151 a 180 dias", id: "credito.vencidosDe151a180Dias", readOnly: readOnly, currency: true },
    { num: "12.5.7", label: "De 181 a 360 dias", id: "credito.vencidosDe181a360Dias", readOnly: readOnly, currency: true },
    { num: "12.5.8", label: "Acima de 361 dias", id: "credito.vencidosAcimaDe361Dias", readOnly: readOnly, currency: true },
    { num: "12.6", label: "Pré-pagamentos no período", id: "credito.prePagamentosNoPeriodo", readOnly: readOnly, currency: true },
    { num: "12.6.1", label: "Montante recebido no período", id: "credito.montanteRecebidoNoPeriodo", readOnly: readOnly, currency: true },
    { num: "12.6.2", label: "Informações impacto", id: "credito.informacoesImpacto", readOnly: readOnly },
    { num: "12.7.1", label: "Valor dívidas adquiridas", id: "credito.valorDividasAdquiridas", readOnly: true, currency: true },
    { num: "12.7.2", label: "% créditos cobertos retenção de risco", id: "credito.creditosCobiertosRetencaoDeRisco", readOnly: true, percent: true },
    { num: "12.7.3", label: "% créditos cobertos retenção outros", id: "credito.creditosCobiertosRetencaoOutros", readOnly: true, percent: true },
    { num: "12.7.4", label: "Valor garantias / Valor carteira", id: "credito.valorGarantisValorCarteira", readOnly: readOnly, percent: true },
    { num: "12.7.5", label: "Periodicidade de avaliação das garantias", id: "credito.periodicidadeDeAvaliacaoDasGarantis", readOnly: readOnly, dropdown: true, options: periodicidadeList },
    { num: "12.7.6", label: "Duration da carteira", id: "credito.durationDaCarteira", readOnly: readOnly },
    { num: "12.7.7", label: "Valor total créditos / Valor emissão", id: "credito.valorTotalCreditosValorEmissao", readOnly: readOnly, percent: true },
    { num: "12.7.8", label: "Outras considerações relevantes", id: "credito.outrasConsideracoesRelevantes", readOnly: readOnly },
    { num: "12.8", label: "Concentração Devedores" },
    { num: "12.8.1", label: "Maior Devedor", id: "credito.maiorDevedor", readOnly: readOnly, percent: true },
    { num: "12.8.2", label: "5 Maiores Devedores", id: "credito.cincoMaioresDevedores", readOnly: readOnly, percent: true },
    { num: "12.8.3", label: "10 Maiores Devedores", id: "credito.dezMaioresDevedores", readOnly: readOnly, percent: true },
    { num: "12.8.4", label: "20 Maiores Devedores", id: "credito.vinteMaioresDevedores", readOnly: readOnly, percent: true },
    { num: "12.9", label: "Devedores > 20%" },
  ];
  
  const arrCredito2 = [
    { num: "12.9.1", label: "CNPJ Devedor 1", idDoc: "credito.cnpjDevedor1", idPerc: "credito.devedor1", readOnly: readOnly, labelDivider: "% Devedor 1" },
    { num: "12.9.2", label: "CNPJ Devedor 2", idDoc: "credito.cnpjDevedor2", idPerc: "credito.devedor2", readOnly: readOnly, labelDivider: "% Devedor 2" },
    { num: "12.9.3", label: "CNPJ Devedor 3", idDoc: "credito.cnpjDevedor3", idPerc: "credito.devedor3", readOnly: readOnly, labelDivider: "% Devedor 3" },
    { num: "12.9.4", label: "CNPJ Devedor 4", idDoc: "credito.cnpjDevedor4", idPerc: "credito.devedor4", readOnly: readOnly, labelDivider: "% Devedor 4" },
    { num: "12.9.5", label: "CNPJ Devedor 5", idDoc: "credito.cnpjDevedor5", idPerc: "credito.devedor5", readOnly: readOnly, labelDivider: "% Devedor 5" }
  ];

  const arrCredito3 = [
    { num: "12.10", label: "Concentração Cedentes" },
    { num: "12.10.1", label: "Maior Cedente", id: "credito.maiorCedente", readOnly: readOnly, percent: true },
    { num: "12.10.2", label: "5 Maiores Cedentes", id: "credito.cincoMaioresCedentes", readOnly: readOnly, percent: true },
    { num: "12.10.3", label: "10 Maiores Cedentes", id: "credito.dezMaioresCedentes", readOnly: readOnly, percent: true },
    { num: "12.10.4", label: "20 Maiores Cedentes", id: "credito.vinteMaioresCedentes", readOnly: readOnly, percent: true },
    { num: "12.11", label: "Cedentes > 20%" }
  ];

  const arrCredito4 = [
    { num: "12.11.1", label: "CNPJ Cedente 1", idDoc: "credito.cnpjCedente1", idPerc: "credito.cedente1", readOnly: readOnly, labelDivider: "% Cedente 1" },
    { num: "12.11.2", label: "CNPJ Cedente 2", idDoc: "credito.cnpjCedente2", idPerc: "credito.cedente2", readOnly: readOnly, labelDivider: "% Cedente 2" },
    { num: "12.11.3", label: "CNPJ Cedente 3", idDoc: "credito.cnpjCedente3", idPerc: "credito.cedente3", readOnly: readOnly, labelDivider: "% Cedente 3" },
    { num: "12.11.4", label: "CNPJ Cedente 4", idDoc: "credito.cnpjCedente4", idPerc: "credito.cedente4", readOnly: readOnly, labelDivider: "% Cedente 4" },
    { num: "12.11.5", label: "CNPJ Cedente 5", idDoc: "credito.cnpjCedente5", idPerc: "credito.cedente5", readOnly: readOnly, labelDivider: "% Cedente 5" }
  ]
  
  const fields = (arr) => {
    return arr.map(({ num, label, id, readOnly, percent, currency, dropdown, options, textField = 'value', valueField = 'key' }) =>
      id ? (
        <div className="row d-flex align-items-center my-lg-2 mb-4 mt-2" key={id}>
          <div className="col-1 mt-lg-2 mt-0 fw-400">{num}</div>
          <div className="col-lg-3 col-11 text-lg-left text-center mt-lg-2 mt-0 fw-400 px-2 pr-lg-2 pr-5">{label}</div>
          <div className="col-lg col-12">
            {percent ? (
              <Field
                component={PercentageInput}
                outerClass="m-0"
                name={id}
                inputId={id}
                readOnly={readOnly}
                decimalScale={2}
                limitToHundred={true}
              />
            ) : currency ? (
              <Field
                component={MaskedInput}
                outerClass="m-0"
                inputId={id}
                name={id}
                readOnly={readOnly}
                prefix="R$ "
                placeholder={CURRENCY.placeholder}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
              />
            ) : dropdown ? (
              <Field
                component={DropdownListInput}
                outerClassName="m-0"
                inputId={id}
                name={id}
                options={options}
                textField={textField}
                valueField={valueField}
                readOnly={readOnly}
              />
            ) : (
              <Field
                component={Input}
                outerClassName="m-0"
                inputId={id}
                name={id}
                readOnly={readOnly}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="row d-flex align-items-center my-2 py-1" key={num}>
          <div className="col-1 mt-lg-2 mt-0 fw-400">{num}</div>
          <div className="col-lg-3 col-11 text-lg-left text-center mt-lg-2 mt-0 fw-400 px-2 pr-lg-2 pr-5">{label}</div>
        </div>
      )
    );
  }

  const fieldsDocumentPercent = (arr) => {
    return arr.map(({ num, label, idDoc, idPerc, readOnly, labelDivider }) =>
      <div className="row d-flex align-items-center my-lg-2 mb-4 mt-2" key={idDoc}>
        <div className="col-1 mt-lg-2 mt-0 fw-400">{num}</div>
        <div className="col-lg-3 col-11 text-lg-left text-center mt-lg-2 mt-0 fw-400 px-2 pr-lg-2 pr-5">{label}</div>
        <div className="col-lg col-12">
          <Field
            name={idDoc}
            maxLength="18"
            inputId={idDoc}
            outerClass="m-0"
            readOnly={readOnly}
            component={MaskedFormatInput}
            formatMask={customFormatCnpjCpf}
          />
        </div>
        <div className="col-lg-auto mt-lg-0 mt-2 fw-400 text-center">{labelDivider}</div>
        <div className="col-lg col-12">
          <Field
            component={PercentageInput}
            outerClass="m-0"
            name={idPerc}
            inputId={idPerc}
            readOnly={readOnly}
            decimalScale={2}
            limitToHundred={true}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid px-0">
      <div className="column shadow-custom bg-white rounded-12 col-12 px-4 pt-1 pb-3">
        <p className="h3 page-head__title my-3 mb-0">12. Informações sobre Crédito</p>

        {type && (type == 'CRA' ? fields(arrCredito1CRA) :  type == 'CRI' ? fields(arrCredito1CRI) : [])}
        {fieldsDocumentPercent(arrCredito2)}
        {fields(arrCredito3)}
        {fieldsDocumentPercent(arrCredito4)}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("RegulatorioForm")(state),
  periodicidadeDeAvaliacaoDasGarantisList: state.regulatorioReducer.regulatorioForm?.periodicidadeDeAvaliacaoDasGarantisList || [],
});

const mapDispacthToProps = (dispatch) => bindActionCreators({
  change
}, dispatch);

export default connect(mapStateToProps, mapDispacthToProps)(Credito);
