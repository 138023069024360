import { initialize } from 'redux-form';
import { converterParaFormatoEUA, extrairDiaMes, getCurrentDateTimeStringNormal, getDateInvertedFormatedFromString } from '../../../../utils/helpers/DateTimeHelper';
import { getBooleanValueFromString, getStringBooleanValue } from '../../../../utils/helpers/BooleanHelper';
import { addZerosAEsquerda } from '../../../../utils/helpers/OthersHelpers';
import { extractFromObj } from "../../../../utils/helpers/ObjectHelper";

export function serializeFieldsPatrimonioForm(patrimonio) {
    let patrimonioSerialized = {};
    
    if (patrimonio) {
        patrimonioSerialized = {
            ...patrimonio,
            arquivoImagemEstrutura: patrimonio?.patrimonioSeparadoEstrutura?.arquivoImagemEstrutura || "",
            nomeImagemEstrutura: patrimonio?.patrimonioSeparadoEstrutura?.nomeImagemEstrutura || "",
            descricaoEstrutura: patrimonio?.patrimonioSeparadoEstrutura?.descricaoEstrutura || "",
            dataEncerramentoSocialDF: getCurrentDateTimeStringNormal(patrimonio?.dataEncerramentoSocialDF),
            dataEncerramentoSocialAnual: extrairDiaMes(patrimonio?.dataEncerramentoSocialAnual),
            originacao: patrimonio?.originacao ? {
                ...patrimonio.originacao,
                contaOrigem: {
                    ...patrimonio.originacao?.contaOrigem,
                    nomeExibicao: formatNomeExibicao(patrimonio.originacao?.contaOrigem)
                },
                possuiIntegracao: getStringBooleanValue(patrimonio.originacao?.possuiIntegracao)
            } : { possuiIntegracao: "Não" }
        };
    } else {
        patrimonioSerialized = { 
            originacao: { 
                possuiIntegracao: "Não"
            }
         };
    }
    
    return initialize("patrimonioForm", patrimonioSerialized);
}

const formatNomeExibicao = (conta) => 
    `${conta?.codigoBanco || ''}_${addZerosAEsquerda(conta?.agencia || '', 5)}/${addZerosAEsquerda(conta?.conta || '', 10)}${conta?.contaDigito ? `-${conta.contaDigito}` : ''}`;
  

// recebimento
export function serializeContasOrigem(contaList) {
    return contaList?.map(conta => conta && ({
        ...conta,
        nomeExibicao: formatNomeExibicao(conta)
    })) || [];
}

export function deserializeFieldsPatrimonioForm(patrimonio) {
    let { arquivoImagemEstrutura, nomeImagemEstrutura, descricaoEstrutura, dataEncerramentoSocialAnual, idEmissor, emissor, ...newPatrimonio } = patrimonio;

    let patr = {
        ...newPatrimonio,
        descricao: patrimonio?.descricao || "",
        quantidadeEmissoes: Number(patrimonio?.quantidadeEmissoes) || 0,
        quantidadeSeries: Number(patrimonio?.quantidadeSeries) || 0,
        status: patrimonio?.status || 1,
        idProduto: patrimonio?.idProduto?.key || patrimonio?.idProduto || 0,
        idUsuarioGestao: patrimonio?.idUsuarioGestao?.id || patrimonio?.idUsuarioGestao || 0,
        idUsuarioServicoCredito: patrimonio?.idUsuarioServicoCredito?.id || patrimonio?.idUsuarioServicoCredito || 0,
        idEmissor: idEmissor?.id || idEmissor || 0,
        idTitulo: patrimonio?.idTitulo?.id || patrimonio?.idTitulo || 0,
        idNaturezaOperacao: patrimonio?.idNaturezaOperacao?.id || patrimonio?.idNaturezaOperacao || 0,
        segmento: patrimonio?.segmento || "",
        agenteFiduciario: patrimonio?.agenteFiduciario || {},
        patrimonioSeparadoEstrutura: {
            arquivoImagemEstrutura: arquivoImagemEstrutura || "",
            nomeImagemEstrutura: nomeImagemEstrutura || "",
            descricaoEstrutura: descricaoEstrutura || "",
        },
        originacao: {
            ...newPatrimonio.originacao,
            possuiIntegracao: getBooleanValueFromString(newPatrimonio.originacao.possuiIntegracao),
            idOperacao: extractFromObj(newPatrimonio.originacao?.operacao, "key"),
            idContaOrigem: extractFromObj(newPatrimonio.originacao?.contaOrigem),
            idOrigemRecurso: extractFromObj(newPatrimonio.originacao?.origemRecurso)
        },
        dataEncerramentoSocialAnual: converterParaFormatoEUA(`${dataEncerramentoSocialAnual}/${new Date().getFullYear()}`),
        // idUsuarioEstruturacao: patrimonio?.idUsuarioEstruturacao?.id || patrimonio?.idUsuarioEstruturacao || 0,
        // regimeFiduciario: typeof patrimonio?.regimeFiduciario == 'boolean' ? patrimonio?.regimeFiduciario : getBooleanValueFromStringNormal(patrimonio?.regimeFiduciario?.nome),
        // idConcentracao: patrimonio?.idConcentracao?.key || patrimonio?.idConcentracao || 0,
        // gestorFiduciario: patrimonio?.gestorFiduciario || {},
        // coordenadorLider: patrimonio?.coordenadorLider || {},
        // assessorLegal: patrimonio?.assessorLegal || {},
        // escriturador: patrimonio?.escriturador || {},
        // bancoLiquidante: patrimonio?.bancoLiquidante || {},
    };

    return patr
}

export function deserializePatrimoniosVinculados(patrimonios, idOperacao) {
    let vinculoPatrimonios = [];

    if(patrimonios && patrimonios.length > 0) {
        vinculoPatrimonios = [
            {
                idOperacao: idOperacao,
                idPatrimonio: patrimonios[0].idPatrimonio,
                patrimonioCodigoB3Cetips: patrimonios.map(item => ({ id: item.id, codigo: item.codigo })),
            }
        ]
    } else {
        vinculoPatrimonios = [
            {
                idOperacao: 0,
                idPatrimonio: 0,
                patrimonioCodigoB3Cetips: [],
            }
        ]
    }

    return vinculoPatrimonios;
}