import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  loadFormAssembleia,
  saveAssembleia,
} from "../../../../actions/assembleia/AssembleiaAction";
import ContainerPage from "../../../common/container/ContainerPage";
import AssembleiaForm from "./form/AssembleiaForm";
import { authorizationFunction } from "../../../../actions/authorization/AuthorizationAction";
import { ASSEMBLEIA, READ } from "../../../../utils/actionsAndFeaturesAuth";

let AssembleiaIncludePage = (props) => {
  const { saveAssembleia, loadFormAssembleia, authorizationFunction } = props;

  React.useEffect(() => {
    let idAssembleia = props.match.params.idAssembleia;
    async function fetchLoad() {
      await authorizationFunction(
        { idAcao: READ, idFuncionalidade: ASSEMBLEIA, validarAreaAtuacao: true },
        () => loadFormAssembleia(idAssembleia)
      );
    }

    fetchLoad();
  }, []);

  return (
    <ContainerPage>
      <AssembleiaForm
        onSubmit={saveAssembleia}
        readOnly={false}
        floatButtonVisible={true}
        title={"Incluir Assembléia"}
        justView={false}
      />
    </ContainerPage>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authorizationFunction,
      loadFormAssembleia,
      saveAssembleia,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AssembleiaIncludePage);
